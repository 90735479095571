<template>
  <v-app>
    <SingleAppBar title="ยืนยันตน" :backward="true" />
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card flat>
              <v-card-text>
                 <v-form ref="form" v-model="valid" lazy-validation>
                <TextField v-model="token" :title="'รหัสยืนยันตัวตน เช่น VWELL-ABC019'"  required
                  :rules="[(v) => !!v || 'กรุณากรอกข้อมูล', v => (v && v.length >= 3) || 'กรุณากรอกข้อมูลให้ครบถ้วน']" />
                 </v-form>
                <div>
                  “กรุณาแสดงบัตรพนักงานเพื่อรับรหัสยืนยันตัวตน ของท่าน
                  ที่เจ้าหน้าที่ ของสถานพยาบาล ที่ท่านสังกัด”
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- <ListCard/> -->
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
        <v-dialog v-model="dialog" persistent>
        <KYCFailCard @onClose="onDialogClose()" @onSkip="$router.push({ name: 'main'})"/>
      </v-dialog>
      <SiteContentCard :service="service" :content="content"/>
    </v-main>
    <v-footer app height="100">
      <v-btn block large color="primary text-title-2" @click="serviceAuthen()">ยืนยันตัวตน</v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import SingleAppBar from "@/components/common/SingleAppBar";
import KYCFailCard from '@/components/kyc/KYCFailCard'
import SiteContentCard from '@/components/sitecontent/SiteContentCard'
import { mapFields } from "vuex-map-fields";
import TextField from "@/components/form/TextField";
import { getKYC,serviceAuthen,getSiteContent } from "@/api/";
import { mask } from "vue-the-mask";
//import ListCard from "@/components/inbox/ListCard";

export default {
  name: "KYC",
   directives: { mask },
  components: {
    SingleAppBar,
    TextField,
    KYCFailCard,
    SiteContentCard
    //ListCard
  },
  data() {
    return {
      state: false,
      loading: false,
      role: null,
      token: '',
      dialog: false,
      valid: false,
      service: {},
      content:[]
    };
  },
  computed: {
    ...mapFields(["kyc"]),
  },
  methods: {
    serviceAuthen () {
       if (this.$refs.form.validate()) {
      this.loading = true
      serviceAuthen({inviteCode: this.token.trim()},message=>{
          if (message.data.code === 1) {
          const tenantId = message.data.result.tenantId
          const siteId = message.data.result.siteId
          this.service = message.data.result
            getSiteContent({ tenantId: tenantId, siteId: siteId }, message => {
                 if (Array.isArray(message.data.result) && message.data.result.length > 0) {
                   message.data.result.forEach(function (element) { 
                     element.dialog = true
                     element.process = false
                     element.isAccept = null
                     element.tenantId = tenantId
                     element.siteId = siteId
                     element.mode = 'new'
                    })
                   this.content = message.data.result
                 } else {
                    this.$router.push({ name: 'main' })
                 }
              }, error => {
                console.log(error)
              })

          }
         this.loading = false
      },error=>{
          console.log(error)
          this.dialog = true
          this.loading = false
      })
      
      
      }
    },
    getKYC() {
        if (this.$refs.form.validate()) {
      this.loading = true
      getKYC(
        {
          group_id: this.kyc.groupId,
          token: this.token.trim(),
          rid: "0",
          uuid: this.$auth.user.uniqueId,
          email: this.$auth.role.email,
          phone: this.$auth.role.mobile,
          citizenId: this.$auth.role.cid,
          tenantId: this.kyc.tenantId,
        },
        message => {
          if (message.data.code === 1) {
            if (
              Array.isArray(message.data.result) &&
              message.data.result.length > 0
            ) {
                 this.$router.replace({name: 'main'})
             
            }  else {
              this.dialog = true
              this.loading = false
            }
          }
        }, error => {
          console.log(error)
          this.dialog = true
          this.loading = false
        }
      );
        }
    },
     onDialogClose () {
      this.dialog=false
    },
    async fetchData() {
      this.role = this.$offlineStorage.get("role");
      // console.log(this.kyc);
      // const site = this.$offlineStorage.get("site");
      // const tenant = this.$offlineStorage.get("tenant");
    },
  },
  mounted () {
      // this.deleteService();
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
</style>